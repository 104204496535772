/* utility classes */

.w-100 {
    width: 100%;
}

.d-flex {
    display: flex;
}

.d-inline-flex {
    display: inline-flex;
}

.d-grid {
    display: grid;
}

.flex-center {
    align-items: center;
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.align-items-end {
    align-items: flex-end;
}

.flex-column {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-1 {
    flex: 1;
}

.g-2 {
    gap: 2px;
}

.g-4 {
    gap: 4px;
}

.g-8 {
    gap: 8px;
}

.g-10 {
    gap: 10px;
}

.g-16 {
    gap: 16px;
}

.g-24 {
    gap: 24px;
}

.g-32 {
    gap: 32px;
}

.g-40 {
    gap: 40px;
}

.col-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.col-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.col-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.pb-8,
.pb-8:not(:last-child) {
    padding-bottom: 8px;
}

.p-16 {
    padding: 16px;
}

.pt-16 {
    padding-top: 16px;
}

.pr-16 {
    padding-right: 16px;
}

.pl-16 {
    padding-left: 16px;
}

.pb-16 {
    padding-bottom: 16px;
}

.text-right {
    text-align: right;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-white {
    color: #fff !important;
}

.text-light {
    color: var(--text-light);
}

.text-dark {
    color: var(--text-dark);
}

.text-accent {
    color: var(--accent);
}

.text-green {
    color: var(--green) !important;
}

.text-red {
    color: var(--red);
}

.text-600 {
    font-weight: 600;
}

.text-700 {
    font-weight: 700;
}

.text-6 {
    font-size: 0.375rem;
}

.text-8 {
    font-size: 0.5rem;
}

.text-10 {
    font-size: 0.625rem;
}

.text-12 {
    font-size: 0.75rem;
}

.text-14 {
    font-size: 0.875rem;
    line-height: 1.4;
}

.text-16 {
    font-size: 1rem;
}

.text-18 {
    font-size: 1.125rem !important;
}

.text-large {
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 1.3;
}

.border-0 {
    border: none !important;
}

.border-12 {
    border-radius: 12px;
}

.lh-1 {
    line-height: 1;
}

.cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 1;
}

.scroll-y {
    overflow-y: auto;
}

.h-100 {
    height: 100%;
}

.slide-up {
    cursor: pointer;
    will-change: transform;
    transition: transform var(--transition);

    &:hover {
        transform: translateY(-5px);
    }
}

.zoom-in {
    cursor: pointer;
    will-change: transform;
    transition: transform var(--transition);

    &:hover {
        transform: scale(1.05);
    }
}