@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?8d6sln');
  src:  url('fonts/icomoon.eot?8d6sln#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?8d6sln') format('truetype'),
    url('fonts/icomoon.woff?8d6sln') format('woff'),
    url('fonts/icomoon.svg?8d6sln#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-deposit:before {
  content: "\e92b";
}
.icon-withdraw:before {
  content: "\e92c";
}
.icon-minus:before {
  content: "\e926";
}
.icon-plus:before {
  content: "\e928";
}
.icon-reset:before {
  content: "\e929";
}
.icon-pan:before {
  content: "\e92a";
}
.icon-headset-light:before {
  content: "\e927";
}
.icon-xmark:before {
  content: "\e925";
}
.icon-delete:before {
  content: "\e91f";
}
.icon-logout:before {
  content: "\e920";
}
.icon-lang:before {
  content: "\e921";
}
.icon-aml:before {
  content: "\e922";
}
.icon-security:before {
  content: "\e923";
}
.icon-verification:before {
  content: "\e924";
}
.icon-info-active:before {
  content: "\e91e";
}
.icon-dashboard:before {
  content: "\e91c";
}
.icon-dashboard-active:before {
  content: "\e91d";
}
.icon-home-active:before {
  content: "\e916";
}
.icon-wallet:before {
  content: "\e90a";
}
.icon-actions:before {
  content: "\e90d";
}
.icon-actions-active:before {
  content: "\e90e";
}
.icon-home:before {
  content: "\e90f";
}
.icon-nft:before {
  content: "\e910";
}
.icon-nft-active:before {
  content: "\e911";
}
.icon-trade:before {
  content: "\e912";
}
.icon-wallet-active:before {
  content: "\e913";
}
.icon-theme:before {
  content: "\e917";
}
.icon-info:before {
  content: "\e918";
}
.icon-notification-active:before {
  content: "\e919";
}
.icon-bars-solid:before {
  content: "\e91a";
}
.icon-bars-regular:before {
  content: "\e91b";
}
.icon-headset:before {
  content: "\e908";
}
.icon-collections-active:before {
  content: "\e914";
}
.icon-collections:before {
  content: "\e915";
}
.icon-star:before {
  content: "\e900";
}
.icon-star-o:before {
  content: "\e901";
}
.icon-caret-down:before {
  content: "\e902";
}
.icon-caret-up:before {
  content: "\e903";
}
.icon-chevron-down:before {
  content: "\e904";
}
.icon-chevron-left:before {
  content: "\e905";
}
.icon-chevron-right:before {
  content: "\e906";
}
.icon-chevron-up:before {
  content: "\e907";
}
.icon-user:before {
  content: "\e909";
}
.icon-search:before {
  content: "\e90b";
}
.icon-notification:before {
  content: "\e90c";
}
