.container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    background: var(--widget-light);
    border-top: 1px solid var(--border);
    font-size: 10px;
    color: var(--header-light);

    &.dark {
        background: var(--header-light);
        color: var(--text-light);
    }
}