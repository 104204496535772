/* typography */

body {
    font-family: 'Manrope', sans-serif;
    font-size: 0.75rem;
    line-height: 1.6;
    font-weight: 500;
}

h1, .h1 {
    font-weight: 700;
    font-size: 1.6875rem;
    line-height: 1.42;
}

h2, .h2 {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.4;
}

h3, .h3 {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.3;
}

h4, .h4 {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.3;
}