.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
  z-index: 9999;
}

.title {
  max-width: 300px;
}

.animation {
  height: 180px;
  margin-top: -40px;
  width: auto;
}

@media screen and (min-width: 1024px) {
  .container {
    flex-direction: row;
  }

  .title {
    max-width: unset;
  }

  .animation {
    margin-top: 0;
  }
}
