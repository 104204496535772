// global variables

:root {
    --transition: .3s ease-in-out;
    --widget-dark: #202020;
    --widget-light: #fff;
    --body-dark: #171717;
    --body-light: #fafafa;
    --text-dark: #B8B8B8;
    --text-light: #848484;
    --header-light: #171717;
    --header-dark: #fff;
    --border-dark: #333;
    --border-light: #E8E8E8;
    --label: #848484;
    --accent: #E0F64B;
    --green: #1ABC7B;
    --red: #F13005;
    --element-height: 48px;
    --bottom-nav-height: 0px;
}