.list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;

    &_item {
        display: flex;
        align-items: center;
        gap: 16px;
        line-height: 1;

        i {
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;

            &.sm {
                font-size: 18px;
            }
        }
    }

    @media screen and (min-height: 820px) {
        gap: 40px;
        margin-top: 40px;
    }
}

.logo {
    display: inline-flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 16px;

    @media screen and (min-height: 820px) {
        margin-bottom: 30px;
    }
}