.switch {
    input {
        display: none;
    }

    &_slider {
        height: 24px;
        width: 40px;
        background-color: var(--switch);
        border-radius: 20px;
        position: relative;
        display: block;
        transition: background-color var(--transition);

        &:before {
            content: '';
            box-shadow: 0 3px 1px rgba(0, 0, 0, 0.06);
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: var(--widget-light);

            position: absolute;
            top: 2px;
            left: 2px;
            transition: left var(--transition);
        }
    }

    input:checked + &_slider {
        background-color: var(--accent);

        &:before {
            left: 16px;
        }
    }
}